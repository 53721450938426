import axios from "../axios";
import { notifyDanger, notifySuccess } from "./customFn";
import { asyncLogOut } from "./loginAction";

export const setProfileData = (data) => {
  return {
    type: "SET_PROFILE_DATA",
    payload: data,
  };
};

export const asynCProfileData = () => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_token")}`,
      },
    };
    const url = "/user/myProfile";
    axios
      .get(url, config)
      .then((res) => {
        const { data } = res.data;
        dispatch(setProfileData(data));
      })
      .catch((err) => {
        console.log(err);
        // notifyDanger(err.response.data.message || err.response);
      });
  };
};

export const asyncUpadteProfileData = (data, setIsUpDate, setIsLoading) => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_token")}`,
      },
    };
    const url = "/user/updateProfile";
    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        setIsLoading(false);
        setIsUpDate((p) => !p);
      })
      .catch((err) => {
        setIsUpDate((p) => !p);
        setIsLoading(false);
        notifyDanger(err.response.data.message || "Some Error Occured!");
      });
  };
};

export const asyncUpadtePassword = (data, initialState, setIsLoading) => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_token")}`,
      },
    };
    const url = "/user/updatePassword";
    axios
      .put(url, data, config)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        dispatch(asyncLogOut())
        initialState();
        if (setIsLoading) {
          setIsLoading(false);
        }
      })
      .catch((err) => {
        if (setIsLoading) {
          setIsLoading(false);
        }
        notifyDanger(err.response.data.message || "Some Error Occured!");
      });
  };
};

export const asyncPostCourseAnalytics = (data) => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_token")}`,
      },
    };
    const url = "/user/post-course-analytics";
    axios
      .post(url, data, config)
      .then((res) => {
        const { message } = res.data;

        // notifySuccess(message);
      })
      .catch((err) => {
        // notifyDanger(err.response.data.message || "Some Error Occured!");
      });
  };
};

export const setMonthCourseAnalytics = (data) => {
  return {
    type: "SET_MONTH_COURSE_ANALYTICS",
    payload: data,
  };
};

export const asyncGetMonthCourseAnalytics = (paramObj, setIsLoading) => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_token")}`,
      },
      params: paramObj,
    };
    const url = "/user/get-month-course-analytics";
    axios
      .get(url, config)
      .then((res) => {
        const { message, data } = res.data;
        setIsLoading(false);
        dispatch(setMonthCourseAnalytics(data));
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
};

export const asyncGetWeekCourseAnalytics = (paramObj, setIsLoading) => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_token")}`,
      },
      params: paramObj,
    };
    const url = "/user/get-week-course-analytics";
    axios
      .get(url, config)
      .then((res) => {
        const { message, data } = res.data;
        setIsLoading(false);
        dispatch(setMonthCourseAnalytics(data));
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
};

export const asyncGetLast4WeekCourseAnalytics = (paramObj, setIsLoading) => {
  return (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_token")}`,
      },
      params: paramObj,
    };
    const url = "/user/last-week-course-analytics";
    axios
      .get(url, config)
      .then((res) => {
        const { message, data } = res.data;
        setIsLoading(false);
        dispatch(setMonthCourseAnalytics(data));
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
};
export const asyncForgotPassword = (data, navigate, initialState) => {
  return (dispatch) => {
    const url = "/user/forgot-password";
    axios
      .post(url, data)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        if (navigate) {
          navigate("/verify-otp");
        }
        if (initialState) {
          initialState();
        }
        localStorage.setItem("otp_email", data.email);
      })
      .catch((err) => {
        notifyDanger(err.response.data.message || "Some Error Occured!");
      });
  };
};

export const asyncVerifyOTP = (data, navigte, initialState) => {
  return (dispatch) => {
    const url = "/user/verify-otp";
    axios
      .post(url, data)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        navigte("/new-password");
        initialState();
        localStorage.setItem("otp_email", data.email);
        localStorage.setItem("otp_verify", data.otp);
      })
      .catch((err) => {
        notifyDanger(err.response.data.message || "Some Error Occured!");
      });
  };
};

export const asyncSaveNewPassword = (data, navigte, initialState) => {
  return (dispatch) => {
    const url = "/user/reset-password";
    axios
      .post(url, data)
      .then((res) => {
        const { message } = res.data;
        notifySuccess(message);
        navigte("/login");
        initialState();
        localStorage.removeItem("otp_email");
        localStorage.removeItem("otp_verify");
      })
      .catch((err) => {
        notifyDanger(err.response.data.message || "Some Error Occured!");
      });
  };
};
