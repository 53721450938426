import axios from "../axios";
import { notifyDanger, notifySuccess } from "./customFn";

export const setLogin = () => {
  return {
    type: "SET_LOGIN",
    payload: true,
  };
};

export const setLogout = () => {
  return {
    type: "SET_LOGOUT",
    payload: false,
  };
};

export const asyncLogOut = (navigate) => {
  return (dispatch) => {
    const url = "/auth/logout";
    const data = {
      token: localStorage.getItem("oeson_token"),
      id: localStorage.getItem("oeson_id"),
    };
    axios
      .post(url, data)
      .then((res) => {
        localStorage.removeItem("oeson_token");
        localStorage.removeItem("oeson_role");
        localStorage.removeItem("oeson_id");
        dispatch(setLogout());
        notifySuccess("Logout successfully!");
        if (navigate) {
          navigate("/");
        }
      })
      .catch((err) => {
        // setIsLoading(false);
        console.log(err);
        // notifyDanger(err.response.data.message);
      });
  };
};

export const asyncLogin = (
  formData,
  navigate,
  initialState,
  setIsLoading,
  setToken,
  setIsLoggedInDevice
) => {
  return (dispatch) => {
    const url = "/auth/login";

    axios
      .post(url, formData)
      .then((res) => {
        const { message, data, token } = res.data;
        dispatch(setLogin());
        localStorage.setItem("oeson_token", token);
        localStorage.setItem("oeson_role", data.typeOfUser);
        localStorage.setItem("oeson_id", data._id);
        notifySuccess(message);
        initialState();
        setIsLoading(false);
        if (setToken) {
          setToken(token);
        }
        if (data?.userOptions?.length > 1) {
          navigate("/user-accounts");
        } else if (data.typeOfUser === "Student") {
          navigate("/dashboard");
        } else {
          navigate("/blog-dashboard/home");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        const status = err?.response?.data?.status || "";
        if (status === 419 && setIsLoggedInDevice) {
          setIsLoggedInDevice(true);
        }
        console.log(err);
        notifyDanger(err.response.data.message);
      });
  };
};

export const updateTypeOfUser = (data, setIsLoading, navigate) => {
  return (dispatch) => {
    const url = "/auth/add-typeofuser";
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("oeson_token")}`,
      },
    };

    console.log(data);

    axios
      .put(url, data, config)
      .then((res) => {
        localStorage.setItem("oeson_role", data.typeOfUser);
        if (data.typeOfUser === "Student") {
          navigate("/dashboard");
        }
        if (data.typeOfUser === "Blogger") {
          navigate("/blog-dashboard/home");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        notifyDanger(err.response.data.message);
      });
  };
};
